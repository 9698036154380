import React from 'react'
import { ButtonHTMLAttributes } from 'react'
import styles from './button.module.css'
import { ButtonLoadingSpinner } from '../LoadingIndicators/Fullscreen'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size: 'sm' | 'md' | 'lg'
  variant: 'contained' | 'alpha-contained' | 'outlined' | 'ghost'
  brand: 'primary' | 'secondary' | 'success' | 'warning' | 'fail'
  icon?: React.ReactElement
  iconDir?: 'left' | 'right'
  text?: string | JSX.Element
  textClass?: string
  isLoading?: boolean
}

type ButtonStyle = {
  padding: {
    [key in ButtonProps['size']]: string
  }
  textSize: {
    [key in ButtonProps['size']]: string
  }
  variant: {
    [key in ButtonProps['variant']]: {
      background: {
        [key in ButtonProps['brand']]?: string
      }
      border?: {
        [key in ButtonProps['brand']]?: string
      }
      textColor: {
        [key in ButtonProps['brand']]?: string
      }
    }
  }
}

const buttonStyles: ButtonStyle = {
  padding: {
    lg: 'py-[13.2px] px-[25px]',
    md: 'py-[13px] px-5',
    sm: 'py-[9px] px-[18px]'
  },
  textSize: {
    lg: 'text-base',
    md: 'text-sm',
    sm: 'text-[10px]'
  },
  variant: {
    contained: {
      textColor: {
        primary: 'text-black active:text-primary-25',
        secondary: 'text-primary active:text-black',
        fail: 'text-black active:text-black',
        success: 'text-success active:text-success',
        warning: 'text-warning active:text-warning'
      },
      background: {
        primary: 'bg-primary-25 hover:shadow-button active:bg-primary-dark',
        secondary: 'bg-secondary hover:shadow-button active:bg-secondary-dark',
        fail: 'bg-fail-10 hover:shadow-button active:bg-fail-10',
        success: 'bg-success-10 hover:shadow-button active:bg-success-10',
        warning: 'bg-warning-10 hover:shadow-button active:bg-warning-10'
      }
    },
    'alpha-contained': {
      textColor: {
        primary: 'text-black',
        secondary: 'text-secondary-dark',
        success: 'text-success',
        warning: 'text-warning',
        fail: 'text-fail',
      },
      background: {
        primary: 'bg-primary-alpha hover:shadow-button active:bg-primary-25',
        secondary: 'bg-secondary-alpha hover:shadow-button active:bg-secondary-25',
        success: 'bg-success-alpha hover:shadow-button active:bg-success-25',
        warning: 'bg-warning-alpha hover:shadow-button active:bg-warning-25',
        fail: 'bg-fail-alpha hover:shadow-button active:bg-fail-25',
      }
    },
    outlined: {
      textColor: {
        primary: 'text-primary hover:text-primary-10',
        secondary: 'text-secondary hover:text-primary active:text-black',
        success: 'text-success hover:text-success-10',
        warning: 'text-warning hover:text-warning-10',
        fail: 'text-fail hover:text-fail-10',
      },
      background: {
        primary: 'bg-transparent hover:bg-primary active:bg-primary-dark',
        secondary: 'bg-transparent hover:bg-secondary active:bg-secondary-dark',
        success: 'bg-transparent hover:bg-success active:bg-success-dark',
        warning: 'bg-transparent hover:bg-warning active:bg-warning-dark',
        fail: 'bg-transparent hover:bg-fail active:bg-fail-dark',
      },
      border: {
        primary: 'border-solid border border-primary',
        secondary: 'border-solid border border-secondary',
        success: 'border-solid border border-success',
        warning: 'border-solid border border-warning',
        fail: 'border-solid border border-fail',
      }
    },
    ghost: {
      textColor: {
        primary: 'text-primary active:text-primary-dark',
        secondary: 'text-secondary active:text-secondary-dark',
        success: 'text-success active:text-success-dark',
        warning: 'text-warning active:text-warning-dark',
        fail: 'text-fail active:text-fail-dark',
      },
      background: {
        primary: 'border-none bg-transparent hover:bg-primary-alpha active:bg-transparent',
        secondary: 'border-none bg-transparent hover:bg-secondary-alpha active:bg-transparent',
        success: 'border-none bg-transparent hover:bg-success-alpha active:bg-success',
        warning: 'border-none bg-transparent hover:bg-warning-alpha active:bg-warning',
        fail: 'border-none bg-transparent hover:bg-fail-alpha active:bg-fail',
      }
    }
  }
}

export function Button({
  size,
  variant = 'contained',
  brand,
  text,
  iconDir,
  icon,
  className,
  textClass,
  disabled,
  isLoading = false,
  ...rest
}: ButtonProps): JSX.Element {
  const isDisabled = disabled || isLoading
  const buttonClassNames = `
    flex items-center justify-center
    disabled:opacity-30 disabled:cursor-not-allowed rounded-[6px]
    transition duration-300
    font-normal
    ${styles['button']}
    ${buttonStyles.padding[size]}
    ${buttonStyles.textSize[size]}
    ${buttonStyles.variant[variant].background[brand]}
    ${buttonStyles.variant[variant].textColor[brand]}
    ${buttonStyles.variant[variant].border?.[brand] || ''}
    ${isDisabled ? styles['button-disabled'] : ''}
    ${brand === 'primary' && !isDisabled ? styles['button-primary-enabled'] : ''}
    ${className || ''}
  `

  return (
    <button className={buttonClassNames} {...rest} disabled={isDisabled}>
      {icon && (
        <div
          className={`${
            iconDir === 'left' ? 'order-first mr-1' : 'order-last ml-1'
          } text-inherit p-0`}
        >
          {icon}
        </div>
      )}
      <div className={`${textClass} font-medium flex items-center`}>
        <span
          className={`${iconDir === 'left' ? 'order-last' : 'order-first'}`}
        >
          {text}
        </span>
        <ButtonLoadingSpinner isLoading={isLoading} className="ml-2" />
      </div>
    </button>
  )
}

export default Button
