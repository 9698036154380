import * as auth0 from 'auth0-js'
import { useMutation } from 'react-query'
import { Pharmacy, UserDetails, UserRole } from '../api/generated'
import environment from '../config/environment'
import { useAuthState } from '../state/useAuthState'
import { useUserPharmacyState } from '../state/useUserPharmacy'
import useCartManager from './useCartManager'
import { useGraphql } from './useGraphql'
import { useRouter } from 'next/router'

/**
The useAuth0 function provides methods for handling authentication with Auth0.
@returns An object containing the following methods:
loginWithRedirect: A method that redirects the user to the Auth0 login page for authentication.
logOut: A method that logs the user out of their current session.
changePassword: A method that initiates a password change request for the given email.
isAuthenticated: A method that returns an object containing the isLoggedIn boolean and the result, which could either be an empty object or the UserDetails object.
 */

const useAuth0 = (app: 'admin' | 'webshop') => {
  const auth0ClientId =
    app === 'admin'
      ? environment.adminAuth0ClientId
      : app === 'webshop'
        ? environment.webshopAuth0ClientId
        : ''
  const auth0client = new auth0.WebAuth({
    domain: environment.auth0Issuer,
    clientID: auth0ClientId,
    redirectUri: environment.auth0RedirectUrl,
    audience: environment.auth0Audience,
    scope: environment.auth0Scope,
    responseType: 'code',
    state: environment.auth0State
  })

  const graphql = useGraphql()
  const authState = useAuthState()
  const pharmacyState = useUserPharmacyState()
  const { emptyCart } = useCartManager()
  const { setPharmacy } = useUserPharmacyState()
  const { push } = useRouter()

  const profileMutation = useMutation(() => graphql.userDetails({ input: {} }))
  const isLoggedIn = !!authState.userInfo

  const defaultApp = app
  const logOut = async (app: 'admin' | 'webshop' = defaultApp) => {
    if (typeof window !== 'undefined') {
      const redirectUrls: Record<typeof app, string> = {
        admin: environment.adminBaseUrl,
        webshop: environment.webshopBaseUrl
      }
      const redirectUrl = redirectUrls[app]
      let returnTo = ''

      if (RegExp(environment.allowedRedirectDomainsRegex).test(redirectUrl)) {
        returnTo = `&returnTo=${redirectUrl}`
      }

      const url = `${
        environment.apiUrl
      }/auth/logout?redirect_url=${encodeURIComponent(
        `${environment.auth0Issuer}/v2/logout?client_id=${auth0ClientId}${returnTo}`
      )}`

      emptyCart()
      pharmacyState.reset()
      authState.reset()
      await push(url)
    }
  }

  const isAuthenticated: () => Promise<{
    isLoggedIn: boolean
    result: undefined | UserDetails | null
  }> = async () => {
    const userInfo = authState.userInfo
    if (!userInfo) {
      return {
        isLoggedIn: false,
        result: undefined
      }
    }

    try {
      if (userInfo?.roles?.includes(UserRole.Pharmacist)) {
        const pharmacy = (
          await graphql.getUserPharmacy({ input: { default: true } })
        ).getUserPharmacy?.result as Pharmacy
        setPharmacy(pharmacy)
      }
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.extensions?.code === 'ERROR_UNAUTHORIZED') {
        emptyCart()
      }
    }

    return {
      isLoggedIn: !!userInfo,
      result: userInfo
    }
  }

  function getCookie(name: string): string | null {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)

    if (parts.length === 2) {
      const lastPart = parts.pop()
      if (lastPart) {
        return lastPart.split(';').shift() || null
      }
    }

    return null
  }

  const setUserAuthenticationIfNotSet = async () => {
    const tokenValue = getCookie('gb-token')
    if (!authState.userInfo || !tokenValue) {
      return await setUserAuthentication()
    } else {
      return {
        isLoggedIn: true,
        result: authState.userInfo
      }
    }
  }

  const setUserAuthentication = async () => {
    try {
      const checkAuth = await profileMutation.mutateAsync()
      const result = checkAuth.userDetails.user

      if (result) {
        authState.setUserInfo({
          coverage: result.coverage,
          email: result.email,
          firstName: result.firstName,
          id: result.id,
          insuranceType: result.insuranceType,
          lastName: result.lastName,
          permissions: result.permissions,
          roles: result.roles,
          billingAddress: {
            city: result.billingAddress?.city || '',
            country: result.billingAddress?.country || '',
            createdAt: result.billingAddress?.createdAt,
            houseNumber: result.billingAddress?.houseNumber || '',
            id: result.billingAddress?.id || 0,
            phoneNumber: result.billingAddress?.phoneNumber || '',
            primary: true,
            state: result.billingAddress?.state || '',
            street: result.billingAddress?.street || '',
            updatedAt: result.billingAddress?.updatedAt,
            zip: result.billingAddress?.zip || '',
            company: result.billingAddress?.company,
            dateOfBirth: result.billingAddress?.dateOfBirth,
            firstName: result.billingAddress?.firstName,
            lastName: result.billingAddress?.lastName,
            notes: result.billingAddress?.notes
          },
          dateOfBirth: result.dateOfBirth,
          insuranceContact: result.insuranceContact,
          insuranceName: result.insuranceName,
          name: ''.concat(result.firstName, ' ', result.lastName),
          phoneNumber: result.billingAddress?.phoneNumber,
          shippingAddress: {
            city: result.shippingAddress?.city || '',
            country: result.shippingAddress?.country || '',
            createdAt: result.shippingAddress?.createdAt,
            houseNumber: result.shippingAddress?.houseNumber || '',
            id: result.shippingAddress?.id || 0,
            phoneNumber: result.shippingAddress?.phoneNumber || '',
            primary: false,
            state: result.shippingAddress?.state || '',
            street: result.shippingAddress?.street || '',
            updatedAt: result.shippingAddress?.updatedAt,
            zip: result.shippingAddress?.zip || '',
            company: result.shippingAddress?.company,
            dateOfBirth: result.shippingAddress?.dateOfBirth,
            firstName: result.shippingAddress?.firstName,
            lastName: result.shippingAddress?.lastName,
            notes: result.shippingAddress?.notes
          }
        })
      } else {
        // don't overwrite the doctor key here
        authState.setUserInfo(undefined)
        authState.setUserType(undefined)
      }

      return {
        isLoggedIn: !!result,
        result
      }
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.extensions?.code === 'ERROR_UNAUTHORIZED') {
        emptyCart()
        // WE SHOULD NOT LOGOUT WHEN THERE IS AN UNAUTHORIZED ERROR
        // the AfterUserInitialization should be handling redirect to sign in
        // an interruption to this by doing a redirect means you LOSE THE USERS INTENT!
      }

      return {
        isLoggedIn: false,
        result: undefined
      }
    }
  }

  return {
    /**
     * Log out a user/admin
     */
    logOut,
    /**
     * Handles reset password with Auth0
     * @param email
     * @returns
     */
    changePassword: async (email: string) => {
      return new Promise((resolve, reject) => {
        try {
          auth0client.changePassword(
            {
              email,
              connection: 'Username-Password-Authentication'
            },
            (err, result) => {
              if (err) {
                console.error(err)
                return
              }

              return result
            }
          )
          resolve("We've just sent you an email to reset your password.")
        } catch (e) {
          reject(e)
        }
      })
    },

    isDoctor: authState.userType === 'doctor',

    /**
     * Checks if user is authenticated
     * @returns boolean
     */
    isAuthenticated,
    setUserAuthenticationIfNotSet,
    setUserAuthentication,
    isLoggedIn,
    isLoading: profileMutation.isLoading
  }
}

export default useAuth0

export const openRoutes = [
  '/',
  '/forgotpassword',
  '/resetpassword',
  '/signup',
  '/signin',
  '/ncm',
  '/doccheck'
]
