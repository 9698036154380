import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import Button from '../../Button'
import { useRouter } from 'next/router'
import useAuth0 from '@gruene-brise/data-access/lib/hooks/useAuth0'

interface UnauthorizationErrorProps {
  shouldRedirect?: boolean
}
const UnauthorizationError: React.FC<UnauthorizationErrorProps> = ({
  shouldRedirect = true
}) => {
  const { t } = useTranslation()
  const { replace } = useRouter()
  const { logOut } = useAuth0('admin')

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-center gap-5">
      <FaTimes className="text-fail text-4xl" />
      <p className="font-semibold text-xl">
        {t('You do not have permission to access this route')}
      </p>

      {shouldRedirect ? (
        <Button
          className="!font-semibold"
          size="md"
          text={t('Go to dashboard')}
          variant="contained"
          brand="secondary"
          onClick={() => replace('/')}
        />
      ) : (
        <Button
          className="!font-semibold"
          size="md"
          text={t('Logout')}
          variant="contained"
          brand="secondary"
          onClick={() => logOut('admin')}
        />
      )}
    </div>
  )
}

export default UnauthorizationError
